import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const Email = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            email
          }
        }
      }
    `
  )

  const email = `mailto:${site.siteMetadata.email}`

  return (
    <StyledEmail href={email} target="_blank" rel="noopener noreferrer">contato@ouroestamparia.com.br</StyledEmail>
  )
}

export default Email

const StyledEmail = styled.a`
 word-break: break-all; 
`