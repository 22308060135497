import React from "react"
import styled from "styled-components"
import { Container } from "../global"

const Header = ({ header1, header2, subheader1, subheader2, subheader3 }) => {

  return (
    <HeaderWrapper id="top">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <h1>
              {header1}
              <br />
              {header2}
            </h1>
            <ol>
              <li>{subheader1}</li>
              <li>{subheader2}</li>
              <li>{subheader3}</li>
            </ol>
          </HeaderTextGroup>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default Header

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.background.light};
  padding: 160px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 120px 0 80px 0;
  }
`

const HeaderTextGroup = styled.div`
  margin: 0;
  padding-top: 16px;

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.primary};
  }

  ol {
    pading-inline-start: 25px;
  }

  p, li {
    margin-bottom: 24px;
    ${props => props.theme.font_size.regular}
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`
