import React, { Component } from "react"
import styled from "styled-components"

import { Section, Container, ButtonAnchor } from "../components/global"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import WhatsButton from "../components/common/whatsfloating"

import Header from "../components/sections/orcamentoHeader"
import { Link } from "gatsby"


import Footer from "../components/sections/footer"
import Email from "../components/email"

const MODEL_PRICES = [
  {
    model: "camiseta",
    price: 25.5,
  },
  {
    model: "oversized",
    price: 43.5,
  },
  {
    model: "estonada",
    price: 43.5,
  },
  {
    model: "camisetav",
    price: 27.5,
  },
  {
    model: "babylook",
    price: 25.5,
  },
  {
    model: "babylookv",
    price: 27.5,
  },
  {
    model: "infantil",
    price: 23.5,
  },
  {
    model: "mangalonga",
    price: 35.5,
  },
  {
    model: "infantillonga",
    price: 33.5,
  },
  {
    model: "regata",
    price :25.5,
  },
  {
    model: "machao",
    price: 25.5,
  },
  {
    model: "polo",
    price: 44.5,
  },
  {
    model: "raglan",
    price: 28.5,
  },
  {
    model: "blusao",
    price: 60,
  },
  {
    model: "canguru",
    price: 75,
  },
]

const SIZES_PRICES = [
  {
    size: "pp",
    price: 0,
  },
  {
    size: "p",
    price: 0,
  },
  {
    size: "m",
    price: 0,
  },
  {
    size: "g",
    price: 0,
  },
  {
    size: "gg",
    price: 0,
  },
  {
    size: "xg",
    price: 5,
  },
  {
    size: "xxg",
    price: 10,
  },
  {
    size: "nobre",
    price :17,
  }
]


export default class CamisetasPage extends Component {

  state = {
    selectedType: "brilho",
    selectedModel: "camiseta",
    selectedColor: "branco",
    selectedSize: "pp",
    isFrente: "nao",
    frenteLargura: 27,
    frenteAltura: 37,
    frenteGola: 7,
    frenteAlinhamento: "centro",
    isCostas: "nao",
    costasLargura: 27,
    costasAltura: 37,
    costasGola: 10,
    costasAlinhamento: "centro",
    isInterna: "nao",
    internaLargura: 10,
    internaAltura: 5,
    internaGola: 2,
    itemPrice: 25.9,
    itemsList: [],
    totalPrice: 0,
    qtd: 0,
    nome: "",
    telefone: "",
    email: "",
    cpfcnpj: "",
    rua: "",
    numero: "",
    complemento: "",
    bairro: "",
    cep: "",
    cidade: "",
    uf:"",
    selectedFrete:"",
    correiosPac: {price:0, time:0},
    correiosSedex: {price:0, time:0},
    jadlogPackage: {price:0, time:0},
    jadlogCom: {price:0, time:0},
    shipmentOption:"correiosPac",
    shipmentPrice:0,
    shipmentTime:0,
    isSend: false,
    isSended: false,
    isShipmentToCheck: false,
    isShipmentChecked: false,
  }

  updatePrice = () => {
    let price = MODEL_PRICES.find(item => item.model === this.state.selectedModel).price
    price += SIZES_PRICES.find(item => item.size === this.state.selectedSize).price


    if (this.state.selectedType==="fosca") {
      price += this.state.selectedColor==='branco' ? 0 : 8

      if (((this.state.isFrente==='nao')&&(this.state.isCostas==='sim'))||((this.state.isFrente==='sim')&&(this.state.isCostas==='nao'))) {
        price += 10
      }
      if ((this.state.isFrente==='sim')&&(this.state.isCostas==='sim')) {
        price += 15
      }
    } else {
      if (this.state.isFrente==='sim') {
        let areaFrente = this.state.frenteLargura*this.state.frenteAltura
        if ((areaFrente>0)&&(areaFrente<250)) {
          price += 10
        } else if ((areaFrente>=250)&&(areaFrente<600)) {
          price += 20
        } else {
          price += 30
        }
      }

      if (this.state.isCostas==='sim') {
        let areaCostas = this.state.costasLargura*this.state.costasAltura
        if ((areaCostas>0)&&(areaCostas<250)) {
          price += 10
        } else if ((areaCostas>=250)&&(areaCostas<600)) {
          price += 20
        } else {
          price += 30
        }
      }
    }

    this.setState({
      itemPrice: price,
    })
  }

  handleSelectChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    }, () => {
      if (name==='selectedModel') {
        document.getElementById("size").selectedIndex = -1
          if (value==='canguru'||value==='blusao'||value==='polo'||value==='oversized') {
            this.setState({
              selectedType:'brilho',
              selectedColor:'preto',
                }, () => {
                  this.updatePrice()
                })
          }else if (value==='estonada') {
            this.setState({
              selectedType:'brilho',
              selectedColor:'chumbo',
                }, () => {
                  this.updatePrice()
                })
          } else {
            this.updatePrice()
          }
      } else {
        this.updatePrice()
      }
    })
  }

  handleSelectShipmentChange = event => {
    const target = event.target
    const value = target.value

    this.setState({
      shipmentOption: value,
      shipmentPrice: this.state[value].price,
      shipmentTime: this.state[value].time,
    })
  }

  handleInputTextChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    }, () => {
      if (this.orcar.checkValidity() && this.state.itemsList.length>0) {
        this.setState({isSend: true})
      } else {
        this.setState({isSend: false})
      }
    })
  }

  handleInputTextBlur = event => {

    const target = event.target
    let value = target.value
    const name = target.name

    if (target.minLength>0) {
      const minLength = parseInt(target.minLength)
      if (value.length < minLength) value = ""
    }

    if (target.maxLength>0) {
      const maxLength = parseInt(target.maxLength)
      if (value.length > maxLength) value = value.slice(0,maxLength+1)
    }
    this.setState({
      [name]: value,
    })
  }

  cleanAllShipment = () => {
    this.setState({
      cidade: "",
      bairro: "",
      rua: "",
      uf: "",
      cep:"",
      selectedFrete:"",
      correiosPac: {price:0, time:0},
      correiosSedex: {price:0, time:0},
      jadlogPackage: {price:0, time:0},
      jadlogCom: {price:0, time:0},
      shipmentPrice:0,
      shipmentTime:0,
      isShipmentChecked:false,
      isShipmentToCheck:false,
    }, this.calcSubtotal)
  }

  handleInputCEPBlur = event => {
    const target = event.target
    let value = target.value
    const name = target.name
        

    if (target.minLength>0) {      
      const minLength = parseInt(target.minLength)            
      if (value.length < minLength) 
        {
          
          value = ""
        }
    }

    if (target.maxLength>0) {      
      const maxLength = parseInt(target.maxLength)            
      if (value.length > maxLength) 
        {          
          value = ""
        }
    }

    value = value.replace('.','').replace('-','')    

    this.setState({
      [name]: value,
    }, () => {
      if (value.length === 8) {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({"cep": value,}),
        }
        fetch( 'https://www.ouroboros.com.br/ouro/estamparia/viacep', requestOptions)
            .then( response => {
              if (response.ok) {
                response.json().then(data => { 
                  if (!data.erro) {
                    this.setState({
                      cidade: data.cidade,
                      bairro: data.bairro,
                      rua: data.rua,
                      uf: data.uf,
                      selectedFrete:"",
                      correiosPac: {price:0, time:0},
                      correiosSedex: {price:0, time:0},
                      jadlogPackage: {price:0, time:0},
                      jadlogCom: {price:0, time:0},
                      shipmentPrice:0,
                      shipmentTime:0,
                      isShipmentChecked:false,
                    }, this.calcSubtotal)
                  } else {
                    this.cleanAllShipment()
                  }
                })
              } else {
                this.cleanAllShipment()
              }
            })
      } else {
        this.cleanAllShipment()
      }
    })
  }

  handleInputNumberChange = event => {
    const target = event.target
    let value = parseInt(target.value)
    const name = target.name

    const max = parseInt(target.max)
    const min = parseInt(target.min)

    if (value>max) value=max
    if (value<min) value=min

    this.setState({
      [name]: value,
    }, () => {
      this.updatePrice()
    })
  }

  handleInputQtdChange = event => {
    const target = event.target
    let value = parseInt(target.value)
    const name =  parseInt(target.name)

    const min = parseInt(target.min)
    if (value<min) value=min

    let itemsList = this.state.itemsList
    itemsList[name].itemQtd = value

    this.setState({
      itemsList: itemsList,
      selectedFrete:"",
      correiosPac: {price:0, time:0},
      correiosSedex: {price:0, time:0},
      jadlogPackage: {price:0, time:0},
      jadlogCom: {price:0, time:0},
      shipmentPrice:0,
      shipmentTime:0,
      isShipmentChecked:false,
    }, this.calcSubtotal)
  }

  removeItem = event => {
    const target = event.target
    const name =  parseInt(target.name)

    let itemsList = this.state.itemsList
    itemsList.splice(name,1)

    this.setState({
      itemsList: itemsList,
      selectedFrete:"",
      correiosPac: {price:0, time:0},
      correiosSedex: {price:0, time:0},
      jadlogPackage: {price:0, time:0},
      jadlogCom: {price:0, time:0},
      shipmentPrice:0,
      shipmentTime:0,
      isShipmentChecked:false,
    }, this.calcSubtotal)
  }

  addItem = event => {
    let itemsList = this.state.itemsList
    let auxType = this.state.selectedType
    if ((this.state.isFrente==="nao")&&(this.state.isCostas==="nao")) auxType="n/a"

    let frenteLxA = 'nao'
    if (this.state.isFrente==='sim') frenteLxA=this.state.frenteLargura+'X'+this.state.frenteAltura

    let costasLxA = 'nao'
    if (this.state.isCostas==='sim') costasLxA=this.state.costasLargura+'X'+this.state.costasAltura

    itemsList.push({
      selectedType: auxType,
      selectedModel: this.state.selectedModel,
      selectedColor: this.state.selectedColor,
      selectedSize: this.state.selectedSize,
      isFrente: this.state.isFrente,
      frenteLxA: frenteLxA,
      frenteLargura: this.state.frenteLargura,
      frenteAltura: this.state.frenteAltura,
      frenteGola: this.state.frenteGola,
      frenteAlinhamento: this.state.frenteAlinhamento,
      isCostas: this.state.isCostas,
      costasLxA: costasLxA,
      costasLargura: this.state.costasLargura,
      costasAltura: this.state.costasAltura,
      costasGola: this.state.costasGola,
      costasAlinhamento: this.state.costasAlinhamento,
      isInterna: this.state.isInterna,
      internaLargura: this.state.internaLargura,
      internaAltura: this.state.internaAltura,
      internaGola: this.state.internaGola,
      itemPrice: this.state.itemPrice,
      itemQtd: 1
    })

    this.setState({
      itemsList: itemsList,
      selectedFrete:"",
      correiosPac: {price:0, time:0},
      correiosSedex: {price:0, time:0},
      jadlogPackage: {price:0, time:0},
      jadlogCom: {price:0, time:0},
      shipmentPrice:0,
      shipmentTime:0,
      isShipmentChecked:false,
    }, this.calcSubtotal)

    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq('track', 'Adição ao carrinho')
      }
    }

  }

  calcSubtotal = () => {
    let itemsList = this.state.itemsList
    let subtotal = 0
    let account = 0

    itemsList.map(item => {
      subtotal+=item.itemQtd*item.itemPrice
      account+=item.itemQtd
    })

    this.setState({
      totalPrice: subtotal,
      qtd: account,
    }, () => {

      if (this.orcar.cep.checkValidity() && this.state.itemsList.length>0) {
        this.setState({isShipmentToCheck: true})
      } else {
        this.setState({isShipmentToCheck: false})
      }
      
      if (this.orcar.checkValidity() && this.state.itemsList.length>0) {
        this.setState({isSend: true})
      } else {
        this.setState({isSend: false})
      }
    })

    // return subtotal
  }

  send = () => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          itemsList: this.state.itemsList,
          totalPrice: this.state.totalPrice,
          shipmentPrice: this.state.shipmentPrice,
          shipmentTime: this.state.shipmentTime,
          shipmentOption: this.state.shipmentOption,
          nome: this.state.nome,
          telefone: this.state.telefone,
          email: this.state.email,
          cpfcnpj: this.state.cpfcnpj,
          rua: this.state.rua,
          numero: this.state.numero,
          complemento: this.state.complemento,
          bairro: this.state.bairro,
          cep: this.state.cep,
          cidade: this.state.cidade,
          uf: this.state.uf
        }),
    }

    fetch('https://www.ouroboros.com.br/ouro/notificacoes/trello', requestOptions)
        .then(response => {
          if (response.status===200) {
            this.setState({isSended:true})
          }
        })
    
    if (typeof window !== "undefined") {            
      if (window.fbq != null) {        
        window.fbq('track', 'Início da finalização da compra')
      }
      if (window.gtag !=null) {        
        window.gtag("event", "conversion", { "send_to": "AW-10872858549/U7s3CLCkwqsDELXPysAo", "event_callback":"printar"})
      }
    }
  }

  checkCalcFrete = () => {
    if (this.orcar) {
      if (this.orcar.cep) {
        return this.orcar.cep.checkValidity()
      } else {
        return false
      }
    } else {
      return false
    }
  }

  cleanShipment = () => {
    this.setState({
      correiosPac: {price:0, time:0},
      correiosSedex: {price:0, time:0},
      jadlogPackage: {price:0, time:0},
      jadlogCom: {price:0, time:0},
      shipmentPrice:0,
      shipmentTime:0,
      isShipmentChecked: false,
    })
  }

  checkShipment = () => {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({
              "to": {
                "postal_code": this.state.cep
              },
              "package": {
                "height": 2*this.state.qtd,
                "width": 25,
                "length": 30,
                "weight": 0.2*this.state.qtd
              },
              "insurance_value": this.state.totalPrice,
            }),
        }

        fetch( 'https://www.ouroboros.com.br/ouro/estamparia/melhorenvio', requestOptions)
            .then( response => {
            if (response.ok) {
                response.json().then(data => {
                  if (!data.erro) {
                    this.setState({
                      correiosPac: {price:parseFloat(data.correiosPacPrice)+7, time:parseFloat(data.correiosPacTime)+9},
                      correiosSedex: {price:parseFloat(data.correiosSedexPrice)+7, time:parseFloat(data.correiosSedexTime)+9},
                      jadlogPackage: {price:parseFloat(data.jadlogPackagePrice)+7, time:parseFloat(data.jadlogPackageTime)+9},
                      jadlogCom: {price:parseFloat(data.jadlogComPrice)+7, time:parseFloat(data.jadlogComTime)+9},
                      isShipmentChecked: true,
                    }, () => {
                      this.setState({
                        shipmentPrice: this.state[this.state.shipmentOption].price,
                        shipmentTime: this.state[this.state.shipmentOption].time,
                      })
                    })
                  } else {
                    this.cleanShipment()
                  }
                })
              } else {

                  this.cleanShipment()
              } 
            })

    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq('track', 'Adição de informações de pagamento')
      }
    }        
  }

  validate = () => {
    if (this.orcar) {
      if (this.orcar.checkValidity() && this.state.itemsList.length>0) {
        return false
      } else {
        return true
      }
    }
  }

  continue = () => {
    this.setState({
      isSended: false,
    })
  }

  render() {
    return (
      <Layout>
      <WhatsButton />
        <SEO
          title="Faça um Orçamento Online. Simples e Rápido."
          description="Camisetas estampadas sem pedido mínimo. Orce exatamente o que precisa."
          pathname="/orcamento"
        />
        <Navigation />
        <Header
          header1="Faça uma simulação para o seu pedido."
          header2="Ferramenta simples e rápida."
          subheader1="Escolha as opções;"
          subheader2="Veja o valor e adicione a peça;"
          subheader3="Confira a lista no final."
        />
        <form ref={form => this.orcar = form}>
          <Section>
            <StyledContainer>
              <h2>Escolha o Estilo</h2>
              <select name="selectedType" id="type" onChange={this.handleSelectChange}>
                <option value="brilho">Digital Brilho (DTF)</option>
                {(this.state.selectedModel!=="estonada" && this.state.selectedModel!=="oversized" && this.state.selectedModel!=="polo" && this.state.selectedModel!=="blusao" && this.state.selectedModel!=="canguru") && (<option value="fosca">Digital Fosco (DTG)</option>)}
              </select>
              <DetalhesTecnicas>
                {(this.state.selectedType==="brilho") && (<Observacoes>Processo DTF. Técnica moderna de transferência. Estampa forte e com brilho, aspecto próximo ao da serigrafia tradicional.</Observacoes>)}
                {(this.state.selectedType==="fosca") && (<Observacoes>Processo DTG simplificado, com pouca base branca. Estampa com aspecto fosco e leve. Toque suave.</Observacoes>)}
              </DetalhesTecnicas>
              <Observacoes>Dúvidas? Nos chame no whatsapp!</Observacoes>
              
            </StyledContainer>
            <StyledContainer>
              <h2>Escolha o Modelo</h2>
              <select name="selectedModel" id="model" onChange={this.handleSelectChange}>
                <option value="camiseta">Camiseta</option>
                <option value="oversized">Oversized</option>
                <option value="estonada">Estonada</option>
                <option value="babylook">Baby Look</option>
                <option value="camisetav">Camiseta Gola V</option>
                <option value="babylookv">Baby Look Gola V</option>
                <option value="infantil">Camiseta Infantil</option>
                <option value="mangalonga">Manga Longa</option>
                <option value="infantillonga">Manga Longa Infantil</option>
                <option value="regata">Regata</option>
                <option value="machao">Regata Machao</option>
                <option value="polo">Polo</option>
                <option value="raglan">Raglan</option>
                <option value="blusao">Moletom Simples</option>
                <option value="canguru">Moletom Capuz</option>
              </select>
              <DetalhesTecnicas>
                {(this.state.selectedModel==="estonada" || this.state.selectedModel==="oversized" || this.state.selectedModel==="polo" || this.state.selectedModel==="blusao" || this.state.selectedModel==="canguru") && (<Observacoes>Essa peça só pode ser estampada na técnica Digital Brilho (DTF)</Observacoes>)}
              </DetalhesTecnicas>
            </StyledContainer>
            <StyledContainer>
              <h2>Escolha a Cor da Malha</h2>
              <select name="selectedColor" id="color" onChange={this.handleSelectChange}>
                {(this.state.selectedModel==="camiseta") && (<option value="offwhite">Off White</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="raglan") && (<option value="branco">Branco</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="polo" && this.state.selectedModel!=="raglan" && this.state.selectedModel!=="blusao" && this.state.selectedModel!=="canguru" && this.state.selectedModel!=="camisetav" && this.state.selectedModel!=="babylookv" && this.state.selectedModel!=="regata" && this.state.selectedModel!=="machao") && (<option value="cinzamedio">Cinza Médio</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="polo" && this.state.selectedModel!=="raglan") && (<option value="cinzamescla">Cinza Mescla</option>)}
                {(this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="polo" && this.state.selectedModel!=="raglan") && (<option value="chumbo">Cinza Chumbo</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="raglan") && (<option value="preto">Preto</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="polo" && this.state.selectedModel!=="raglan") && (<option value="pretomescla">Preto Mescla</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="polo" && this.state.selectedModel!=="raglan") && (<option value="canario">Amarelo Canário</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="polo" && this.state.selectedModel!=="raglan" && this.state.selectedModel!=="blusao" && this.state.selectedModel!=="canguru" && this.state.selectedModel!=="camisetav" && this.state.selectedModel!=="babylookv"  && this.state.selectedModel!=="regata" && this.state.selectedModel!=="machao" && this.state.selectedModel!=="mangalonga" && this.state.selectedModel!=="infantillonga") && (<option value="ouro">Amarelo Ouro</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="polo" && this.state.selectedModel!=="raglan" && this.state.selectedModel!=="blusao" && this.state.selectedModel!=="canguru" && this.state.selectedModel!=="camisetav" && this.state.selectedModel!=="babylookv"  && this.state.selectedModel!=="regata" && this.state.selectedModel!=="machao" && this.state.selectedModel!=="mangalonga" && this.state.selectedModel!=="infantillonga") && (<option value="mostarda">Mostarda</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="polo" && this.state.selectedModel!=="raglan" && this.state.selectedModel!=="camisetav" && this.state.selectedModel!=="babylookv"  && this.state.selectedModel!=="regata" && this.state.selectedModel!=="machao") && (<option value="laranja">Laranja</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="polo" && this.state.selectedModel!=="raglan" && this.state.selectedModel!=="blusao" && this.state.selectedModel!=="canguru" && this.state.selectedModel!=="regata" && this.state.selectedModel!=="machao") && (<option value="bebe">Rosa Bebê</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="polo" && this.state.selectedModel!=="raglan" && this.state.selectedModel!=="blusao" && this.state.selectedModel!=="canguru" && this.state.selectedModel!=="regata" && this.state.selectedModel!=="machao" && this.state.selectedModel!=="camisetav" && this.state.selectedModel!=="babylookv" && this.state.selectedModel!=="mangalonga" && this.state.selectedModel!=="infantillonga") && (<option value="goiaba">Rosa Goiaba</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="polo" && this.state.selectedModel!=="raglan" && this.state.selectedModel!=="mangalonga" && this.state.selectedModel!=="infantillonga") && (<option value="pink">Rosa Pink</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="raglan") && (<option value="vermelho">Vermelho</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="polo" && this.state.selectedModel!=="raglan") && (<option value="bordo">Bordô</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="polo" && this.state.selectedModel!=="raglan" && this.state.selectedModel!=="blusao" && this.state.selectedModel!=="canguru" && this.state.selectedModel!=="regata" && this.state.selectedModel!=="machao" && this.state.selectedModel!=="camisetav" && this.state.selectedModel!=="babylookv" && this.state.selectedModel!=="mangalonga" && this.state.selectedModel!=="infantillonga") && (<option value="marrom">Marrom</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="polo" && this.state.selectedModel!=="raglan" && this.state.selectedModel!=="camisetav" && this.state.selectedModel!=="babylookv" && this.state.selectedModel!=="regata" && this.state.selectedModel!=="machao" && this.state.selectedModel!=="mangalonga" && this.state.selectedModel!=="infantillonga") && (<option value="turquesa">Azul Turquesa</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="polo" && this.state.selectedModel!=="raglan" && this.state.selectedModel!=="blusao" && this.state.selectedModel!=="canguru" && this.state.selectedModel!=="regata" && this.state.selectedModel!=="machao" && this.state.selectedModel!=="camisetav" && this.state.selectedModel!=="babylookv" && this.state.selectedModel!=="mangalonga" && this.state.selectedModel!=="infantillonga") && (<option value="jade">Azul Jade</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="raglan") && (<option value="royal">Azul Royal</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="raglan") && (<option value="marinho">Azul Marinho</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="polo" && this.state.selectedModel!=="raglan" && this.state.selectedModel!=="blusao" && this.state.selectedModel!=="canguru" && this.state.selectedModel!=="regata" && this.state.selectedModel!=="machao" && this.state.selectedModel!=="camisetav" && this.state.selectedModel!=="babylookv" && this.state.selectedModel!=="mangalonga" && this.state.selectedModel!=="infantillonga") && (<option value="lilas">Lilás</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="polo" && this.state.selectedModel!=="raglan" && this.state.selectedModel!=="blusao" && this.state.selectedModel!=="canguru" && this.state.selectedModel!=="regata" && this.state.selectedModel!=="machao" && this.state.selectedModel!=="camisetav" && this.state.selectedModel!=="babylookv" && this.state.selectedModel!=="mangalonga" && this.state.selectedModel!=="infantillonga") && (<option value="roxo">Roxo</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="polo" && this.state.selectedModel!=="raglan" && this.state.selectedModel!=="blusao" && this.state.selectedModel!=="canguru" && this.state.selectedModel!=="regata" && this.state.selectedModel!=="machao" && this.state.selectedModel!=="camisetav" && this.state.selectedModel!=="babylookv" && this.state.selectedModel!=="mangalonga" && this.state.selectedModel!=="infantillonga") && (<option value="fluor">Verde Flúor</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="polo" && this.state.selectedModel!=="raglan" && this.state.selectedModel!=="camisetav" && this.state.selectedModel!=="babylookv" && this.state.selectedModel!=="regata" && this.state.selectedModel!=="machao") && (<option value="bandeira">Verde Bandeira</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="polo" && this.state.selectedModel!=="raglan" && this.state.selectedModel!=="blusao" && this.state.selectedModel!=="canguru" && this.state.selectedModel!=="regata" && this.state.selectedModel!=="machao" && this.state.selectedModel!=="camisetav" && this.state.selectedModel!=="babylookv" && this.state.selectedModel!=="mangalonga" && this.state.selectedModel!=="infantillonga") && (<option value="folha">Verde Folha</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel!=="polo" && this.state.selectedModel!=="raglan" && this.state.selectedModel!=="blusao" && this.state.selectedModel!=="canguru") && (<option value="musgo">Verde Musgo</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel==="raglan") && (<option value="cinzapreta">Cinza Manga Preta</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel==="raglan") && (<option value="brancapreta">Branca Manga Preta</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel==="raglan") && (<option value="cinzamarinho">Cinza Manga Marinho</option>)}
                {(this.state.selectedModel!=="estonada") && (this.state.selectedModel!=="oversized") && (this.state.selectedModel==="raglan") && (<option value="cinzabordo">Cinza Manga Bordo</option>)}
              </select>
              <ModeloExample>
                <Modelo model={this.state.selectedModel} color={this.state.selectedColor}></Modelo>
              </ModeloExample>
              <Observacoes>*imagens ilustrativas do nosso <Link to="/camisetas">Catálogo</Link></Observacoes>
            </StyledContainer>
            <StyledContainer>
              <h2>Escolha o Tamanho</h2>
              <select name="selectedSize" id="size" onChange={this.handleSelectChange}>
                {(this.state.selectedModel==="camiseta" || this.state.selectedModel==="blusao" || this.state.selectedModel==="canguru" || this.state.selectedModel==="infantil") && (<option value="pp">PP</option>)}
                <option value="p">P</option>
                <option value="m">M</option>
                <option value="g">G</option>
                <option value="gg">GG</option>
                {(this.state.selectedModel!=="estonada" && this.state.selectedModel!=="oversized" && this.state.selectedModel!=="infantil" && this.state.selectedModel!=="infantillonga" && this.state.selectedModel!=="babylook" && this.state.selectedModel!=="babylookv") && (<option value="xg">XG</option>)}
                {(this.state.selectedModel!=="estonada" && this.state.selectedModel!=="oversized" && this.state.selectedModel!=="babylook" && this.state.selectedModel!=="babylookv" && this.state.selectedModel!=="infantil" && this.state.selectedModel!=="infantillonga" && this.state.selectedModel!=="regata") && (<option value="xxg">XXG</option>)}
                {this.state.selectedModel==="camiseta" && (<option value="nobre">NOBRE</option>)}
              </select>
            </StyledContainer>
            <StyledContainer>
              <h2>Estampa na Frente?</h2>
              <Infos>
                <IsDetailed>
                  <input type="radio" id="frenteSim" name="isFrente" value="sim" onChange={this.handleSelectChange} />
                  <label htmlFor="frenteSim">Sim</label>
                </IsDetailed>
                <IsDetailed>
                  <input type="radio" id="frenteNao" name="isFrente" value="nao" onChange={this.handleSelectChange} />
                  <label htmlFor="frenteNao">Não</label>
                </IsDetailed>
                <Details>
                  <Detail>
                    <div>Largura</div>
                    <input type="number" id="frenteL" name="frenteLargura" value={this.state.frenteLargura} min="1" max="27" onChange={this.handleInputNumberChange} disabled={this.state.isFrente==="nao"}/>
                    <label htmlFor="frenteL">cm</label>
                  </Detail>
                  <Detail>
                    <div>Altura</div>
                    <input type="number" id="frenteA" name="frenteAltura" value={this.state.frenteAltura} min="1" max="37" onChange={this.handleInputNumberChange} disabled={this.state.isFrente==="nao"}/>
                    <label htmlFor="frenteA">cm</label> 
                  </Detail>
                  <Detail>
                    <div>Distância da Gola</div>
                    <input type="number" id="frenteG" name="frenteGola" value={this.state.frenteGola}  min="1" max="36" onChange={this.handleInputNumberChange} disabled={this.state.isFrente==="nao"}/>
                    <label htmlFor="frenteG">cm</label>
                  </Detail>
                  <DetailAlinhamento>
                    <div>Alinhamento</div>
                    <input type="radio" id="frenteEsq" value="esquerda" name="frenteAlinhamento" onChange={this.handleSelectChange} disabled={this.state.isFrente==="nao"}/>
                    <label htmlFor="frenteEsq">esq.</label>
                    <input type="radio" id="frenteCentro" value="centro" name="frenteAlinhamento" onChange={this.handleSelectChange} disabled={this.state.isFrente==="nao"}/>
                    <label htmlFor="frenteCentro">centro</label>
                    <input type="radio" id="frenteDir" value="direita" name="frenteAlinhamento" onChange={this.handleSelectChange} disabled={this.state.isFrente==="nao"}/>
                    <label htmlFor="frenteDir">dir.</label>
                  </DetailAlinhamento>
                </Details>
              </Infos>
              <ModeloExample>
                <Modelo model={this.state.selectedModel} color={this.state.selectedColor}>
                  <Quadro model={this.state.selectedModel} largura={this.state.frenteLargura} altura={this.state.frenteAltura} gola={this.state.frenteGola} alinhamento={this.state.frenteAlinhamento} estampa={this.state.isFrente}></Quadro>
                </Modelo>
              </ModeloExample>
              <Observacoes>*atenção: a área da estampa muda o valor final da camiseta.</Observacoes>
            </StyledContainer>
            <StyledContainer>
              <h2>Estampa nas Costas?</h2>
              <Infos>
                <IsDetailed>
                  <input type="radio" id="costasSim" name="isCostas" value="sim" onChange={this.handleSelectChange} />
                  <label htmlFor="costasSim">Sim</label>
                </IsDetailed>
                <IsDetailed>
                  <input type="radio" id="costasNao" name="isCostas" value="nao" onChange={this.handleSelectChange} />
                  <label htmlFor="costasNao">Não</label>
                </IsDetailed>
                <Details>
                  <Detail>
                    <div>Largura</div>
                    <input type="number" id="costasL" name="costasLargura" value={this.state.costasLargura} min="1" max="27" onChange={this.handleInputNumberChange} disabled={this.state.isCostas==="nao"} />
                    <label htmlFor="costasL">cm</label>
                  </Detail>
                  <Detail>
                    <div>Altura</div>
                    <input type="number" id="costasA" name="costasAltura" value={this.state.costasAltura} min="1" max="37" onChange={this.handleInputNumberChange} disabled={this.state.isCostas==="nao"} />
                    <label htmlFor="costasA">cm</label> 
                  </Detail>
                  <Detail>
                    <div>Distância da Gola</div>
                    <input type="number" id="costasG" name="costasGola" value={this.state.costasGola} min="1" max="35" onChange={this.handleInputNumberChange} disabled={this.state.isCostas==="nao"} />
                    <label htmlFor="costasG">cm</label>
                  </Detail>
                  <DetailAlinhamento>
                    <div>Alinhamento</div>
                    <input type="radio" id="costasEsq" value="esquerda" name="costasAlinhamento" onChange={this.handleSelectChange} disabled={this.state.isCostas==="nao"} />
                    <label htmlFor="costasEsq">esq.</label>
                    <input type="radio" id="costasCentro" value="centro" name="costasAlinhamento" onChange={this.handleSelectChange} disabled={this.state.isCostas==="nao"} />
                    <label htmlFor="costasCentro">centro</label>
                    <input type="radio" id="costasDir" value="direita" name="costasAlinhamento" onChange={this.handleSelectChange} disabled={this.state.isCostas==="nao"} />
                    <label htmlFor="costasDir">dir.</label>
                  </DetailAlinhamento>
                </Details>
              </Infos>
              <ModeloExample>
                <ModeloCostas model={this.state.selectedModel} color={this.state.selectedColor}>
                  <QuadroCostas model={this.state.selectedModel} largura={this.state.costasLargura} altura={this.state.costasAltura} gola={this.state.costasGola} alinhamento={this.state.costasAlinhamento} estampa={this.state.isCostas}></QuadroCostas>
                </ModeloCostas>
              </ModeloExample>
              <Observacoes>*área total ocupada pela estampa</Observacoes>
            </StyledContainer>
            <StyledContainer>
              <h2>Etiqueta Interna?</h2>
              <Infos>
                <IsDetailed>
                  <input type="radio" id="internaSim" name="isInterna" value="sim" onChange={this.handleSelectChange} />
                  <label htmlFor="internaSim">Sim</label>
                </IsDetailed>
                <IsDetailed>
                  <input type="radio" id="internaNao" name="isInterna" value="nao" onChange={this.handleSelectChange} />
                  <label htmlFor="internaNao">Não</label>
                </IsDetailed>
                <Details>
                  <Detail>
                    <div>Largura</div>
                    <input type="number" id="internaL" name="internaLargura" value={this.state.internaLargura} min="1" max="15" onChange={this.handleInputNumberChange} disabled={this.state.isInterna==="nao"} />
                    <label htmlFor="internaL">cm</label>
                  </Detail>
                  <Detail>
                    <div>Altura</div>
                    <input type="number" id="internaA" name="internaAltura" value={this.state.internaAltura} min="1" max="15" onChange={this.handleInputNumberChange} disabled={this.state.isInterna==="nao"} />
                    <label htmlFor="internaA">cm</label> 
                  </Detail>
                  <Detail>
                    <div>Distância da Gola</div>
                    <input type="number" id="internaG" name="internaGola" value={this.state.internaGola} min="1" max="15" onChange={this.handleInputNumberChange} disabled={this.state.isInterna==="nao"} />
                    <label htmlFor="internaG">cm</label>
                  </Detail>
                </Details>
              </Infos>
              <ModeloEtiquetaExample>
                <ModeloEtiqueta color={this.state.selectedColor}>
                  <QuadroEtiqueta largura={this.state.internaLargura} altura={this.state.internaAltura} gola={this.state.internaGola} estampa={this.state.isInterna}></QuadroEtiqueta>
                </ModeloEtiqueta>
              </ModeloEtiquetaExample>
              <Observacoes>*área total ocupada pela estampa</Observacoes>
            </StyledContainer>
            <StyledContainer>
              <h2>Sobre o(s) Arquivo(s)</h2>
              <Details>
                <ol>
                  <li>Usar o formato <strong>.png</strong>;</li>
                  <li>Aplicar a resolução de <strong>300dpi;</strong></li>
                  <li>Deixar o fundo <strong>transparente</strong>;</li>
                  <li>Usar as mesmas <strong>largura</strong> e <strong>altura</strong> nos arquivos;</li>
                  <li>Indicar qual <strong>arquivo</strong> é de qual <strong>item e posição</strong>, ex.:</li>
                  <ol>
                    <li>item Nº1 da lista = n1-peito.png e n1-costas.png</li>
                  </ol>
                  <li>Enviar arquivos das <strong>etiquetas</strong> apenas <strong>uma vez</strong>, ex.:</li>
                  <ol>
                    <li>itens de Nº1 a Nº10 da lista = etiqueta-P.png, etiqueta-M.png, etc.</li>
                  </ol>
                  <li>Enviar os arquivos para o email:</li>
                  <ol>
                    <li><Email></Email></li>
                  </ol>
                </ol>
              </Details>
            </StyledContainer>
            <StyledContainer>
              <h2>Valor do Item</h2>
              <Details>
                <p>Valor unitário: <UnitPrice>R${(this.state.itemPrice).toFixed(2).toString().replace(/[\.]+/g, ',')}</UnitPrice></p>
              </Details>
              <OthersButtons type="button" onClick={this.addItem}>Incluir</OthersButtons>
            </StyledContainer>
            <StyledContainer>
              <h2>Lista do Pedido</h2>
              <AreaDaLista>
                <LinhaDaLista>
                  <ElementoDaLista>Nº</ElementoDaLista>
                  <ElementoDaLista>EST.</ElementoDaLista>
                  <ElementoDaLista>MOD.</ElementoDaLista>
                  <ElementoDaLista>COR</ElementoDaLista>
                  <ElementoDaLista>TAM.</ElementoDaLista>
                  <ElementoDaLista>FR.</ElementoDaLista>
                  <ElementoDaLista>CO.</ElementoDaLista>
                  <ElementoDaLista>QTD</ElementoDaLista>
                  <ElementoDaLista>R$</ElementoDaLista>
                  <ElementoDaLista>X</ElementoDaLista>
                </LinhaDaLista>
                {this.state.itemsList.map((item, i) => (
                  <LinhaDaLista key={i}>
                    <ElementoDaLista>{i+1}</ElementoDaLista>
                    <ElementoDaLista>{item.selectedType}</ElementoDaLista>
                    <ElementoDaLista>{item.selectedModel}</ElementoDaLista>
                    <ElementoDaLista>{item.selectedColor}</ElementoDaLista>
                    <ElementoDaLista>{item.selectedSize}</ElementoDaLista>
                    <ElementoDaLista>{item.frenteLxA}</ElementoDaLista>
                    <ElementoDaLista>{item.costasLxA}</ElementoDaLista>
                    <ElementoDaLista>
                      <input 
                        type="number" 
                        id={'qtd'+i}
                        name={i} 
                        value={item.itemQtd}
                        min="1"
                        onChange={this.handleInputQtdChange}/>
                    </ElementoDaLista>
                    <ElementoDaLista>{item.itemPrice*item.itemQtd}</ElementoDaLista>
                    <ElementoDaLista>
                      <ExcluirButton type="button" name={i} onClick={this.removeItem}>X</ExcluirButton>
                    </ElementoDaLista>
                  </LinhaDaLista>
                ))}
              </AreaDaLista>
              <Subtotal>Subtotal: <strong>R$ {this.state.totalPrice.toFixed(2).toString().replace(/[\.]+/g, ',')}</strong></Subtotal>
              <Observacoes>*salve essa lista, por exemplo: tire um print da tela</Observacoes>
            </StyledContainer>
            <StyledContainer>
              <h2>Frete</h2>
              
                <AreaDoCEP>
                  <label htmlFor="cep">CEP</label>
                  <input placeholder="Somente os 8 dígitos" autoComplete="off" type="text" id="cep" name="cep" value={this.state.cep} onChange={this.handleInputTextChange} onBlur={this.handleInputCEPBlur} minLength="8" maxLength="10" required/>                  
                </AreaDoCEP>

                {/*<OthersButtons disabled={true} type="button" onClick={this.checkShipment}>A Conferir</OthersButtons>*/}
                
                <OthersButtons disabled={!this.state.isShipmentToCheck || this.state.isShipmentChecked} type="button" onClick={this.checkShipment}>Calcular</OthersButtons>
                <Observacoes>*não ativa? Inclua produtos na lista, entre com o CEP, clique fora e aguarde o botão CALCULAR ser ativado </Observacoes>


                <Infos>
                  <ShipmentOptionArea>
                    <ShipmentOption>
                      <input type="radio" id="correiosPac" name="shipmentOption" value="correiosPac" onChange={this.handleSelectShipmentChange} disabled={!this.state.isShipmentChecked} />
                      <label htmlFor="correiosPac">PAC</label>
                    </ShipmentOption>
                    <ShipmentOption>
                      <input type="radio" id="correiosSedex" name="shipmentOption" value="correiosSedex" onChange={this.handleSelectShipmentChange} disabled={!this.state.isShipmentChecked} />
                      <label htmlFor="correiosSedex">SEDEX</label>
                    </ShipmentOption>
                    <ShipmentOption>
                      <input type="radio" id="jadlogPackage" name="shipmentOption" value="jadlogPackage" onChange={this.handleSelectShipmentChange} disabled={!this.state.isShipmentChecked} />
                      <label htmlFor="jadlogPackage">PACKAGE</label>
                    </ShipmentOption>
                    <ShipmentOption>
                      <input type="radio" id="jadlogCom" name="shipmentOption" value="jadlogCom" onChange={this.handleSelectShipmentChange} disabled={!this.state.isShipmentChecked} />
                      <label htmlFor="jadlogCom">COM</label>
                    </ShipmentOption>
                  </ShipmentOptionArea>
                  <ShipmentArea>
                    <ShipmentSelect selection={this.state.isShipmentChecked} selected={this.state.shipmentOption} option="correiosPac">
                      <div>Correios PAC</div>
                      <ShipmentTime>{this.state.correiosPac.time} dias úteis<sup>*</sup></ShipmentTime>
                      <ShipmentPrice><strong>R$ {this.state.correiosPac.price.toFixed(2).toString().replace(/[\.]+/g, ',')}</strong></ShipmentPrice>
                    </ShipmentSelect>
                    <ShipmentSelect selection={this.state.isShipmentChecked} selected={this.state.shipmentOption} option="correiosSedex">
                      <div>Correios SEDEX</div>
                      <ShipmentTime>{this.state.correiosSedex.time} dias úteis<sup>*</sup></ShipmentTime>
                      <ShipmentPrice><strong>R$ {this.state.correiosSedex.price.toFixed(2).toString().replace(/[\.]+/g, ',')}</strong></ShipmentPrice>
                    </ShipmentSelect>
                    <ShipmentSelect selection={this.state.isShipmentChecked} selected={this.state.shipmentOption} option="jadlogPackage">
                      <div>Jadlog Package</div>
                      <ShipmentTime>{this.state.jadlogPackage.time} dias úteis<sup>*</sup></ShipmentTime>
                      <ShipmentPrice><strong>R$ {this.state.jadlogPackage.price.toFixed(2).toString().replace(/[\.]+/g, ',')}</strong></ShipmentPrice>
                    </ShipmentSelect>
                    <ShipmentSelect selection={this.state.isShipmentChecked} selected={this.state.shipmentOption} option="jadlogCom">
                      <div>Jadlog .COM</div>
                      <ShipmentTime>{this.state.jadlogCom.time} dias úteis<sup>*</sup></ShipmentTime>
                      <ShipmentPrice><strong>R$ {this.state.jadlogCom.price.toFixed(2).toString().replace(/[\.]+/g, ',')}</strong></ShipmentPrice>
                    </ShipmentSelect>
                  </ShipmentArea>
                  <Observacoes>*a partir do pagamento até o recebimento</Observacoes>

                  {(this.state.shipmentPrice===0) && (<Subtotal>Frete: <strong>a calcular</strong></Subtotal>)}
                  {(this.state.shipmentPrice!==0) && (<Subtotal>Frete: <strong>R$ {this.state.shipmentPrice.toFixed(2).toString().replace(/[\.]+/g, ',')}</strong></Subtotal>)}
                </Infos>
              
              {/*<ObservacoesFrete>Será feita a cotação nas transportadoras e repassada via whatsapp</ObservacoesFrete>*/}

            </StyledContainer>
            <StyledContainer>
              <h2>Informações de Contato</h2>
              <AreaDasInfos>
                <AreaDaInfo3>
                  <label htmlFor="nome">Nome</label>
                  <input autoComplete="off" type="text" id="nome" name="nome" value={this.state.nome} onChange={this.handleInputTextChange} onBlur={this.handleInputTextBlur} minLength="3" required/>
                </AreaDaInfo3>
                <AreaDaInfo2>
                  <label htmlFor="telefone">Telefone</label>
                  <input placeholder="Só 11 dígitos, sem hífen, espaço, parênteses nem pontuação." autoComplete="off" type="text" id="telefone" name="telefone" value={this.state.telefone} onChange={this.handleInputTextChange} onBlur={this.handleInputTextBlur} minLength="11" maxLength="11" required/>
                </AreaDaInfo2>
                <AreaDaInfo3>
                  <label htmlFor="email">Email</label>
                  <input autoComplete="off" type="email" id="email" name="email" value={this.state.email} onChange={this.handleInputTextChange} required/>
                </AreaDaInfo3>
                <AreaDaInfo2>
                  <label htmlFor="cpfcnpj">CPF ou CNPJ</label>
                  <input placeholder="Só 11 (CPF) ou 14 (CNPJ) dígitos, sem hífen nem pontuação." autoComplete="off" type="text" id="cpfcnpj" name="cpfcnpj" value={this.state.cpfcnpj} onChange={this.handleInputTextChange} onBlur={this.handleInputTextBlur} minLength="11" maxLength="14"/>
                </AreaDaInfo2>
                <AreaDaInfo4>
                  <label htmlFor="rua">Rua</label>
                  <input autoComplete="off" type="text" id="rua" name="rua" value={this.state.rua} onChange={this.handleInputTextChange} onBlur={this.handleInputTextBlur} minLength="3" required/>
                </AreaDaInfo4>
                <AreaDaInfo>
                  <label htmlFor="numero">Nº</label>
                  <input placeholder="Dígitos ou S/N" autoComplete="off" type="text" id="numero" name="numero" value={this.state.numero} onChange={this.handleInputTextChange} required/>
                </AreaDaInfo>
                <AreaDaInfo5>
                  <label htmlFor="complemento">Complemento</label>
                  <input autoComplete="off" type="text" id="complemento" name="complemento" value={this.state.complemento} onChange={this.handleInputTextChange} />
                </AreaDaInfo5>
                <AreaDaInfo3>
                  <label htmlFor="bairro">Bairro</label>
                  <input autoComplete="off" type="text" id="bairro" name="bairro" value={this.state.bairro} onChange={this.handleInputTextChange} required/>
                </AreaDaInfo3>
                <AreaDaInfo2>
                  <label htmlFor="cidade">Cidade</label>
                  <input autoComplete="off" type="text" id="cidade" name="cidade" value={this.state.cidade} onChange={this.handleInputTextChange} required/>
                </AreaDaInfo2>
              </AreaDasInfos>
                <Observacoes>se os campos permanecem vazios, eles estão incorretos</Observacoes>

                <EnviarButton type="button" onClick={this.send} disabled={!this.state.isSend || this.state.isSended || !this.state.isShipmentChecked}>Enviar</EnviarButton>
                {/*<EnviarButton type="button" onClick={this.send} disabled={!this.state.isSend || this.state.isSended}>Enviar</EnviarButton>*/}
            </StyledContainer>
              { this.state.isSended && (
                <StyledContainer>
                  <h2>Orçamento Enviado</h2>
                  <Details>
                    <ul>
                      <li>Você receberá um email com os detalhes do seu pedido;</li>
                      <li>Aguardamos o envio das artes das estampas;</li>
                      <li>Entraremos em contato por whatsapp;</li>
                      <li><strong>p.s.: veja como acelerar o seu pedido:</strong></li>
                      <ol>
                        <li>Efetue o pagamento (pix CNPJ: 26836959000155);</li>
                        <li>Envie o comprovante para o nosso whatsapp;</li>
                      </ol>
                      
                    </ul>
                  </Details>
                  <OthersButtons disabled={!this.state.isSended} type="button" onClick={this.continue}>Reenviar</OthersButtons>
                </StyledContainer>

              )}
          </Section>
        </form>
        <Footer />
    </Layout>
    )
  }
}

const handleModel = model => {

  switch (model) {
    case "camisetav":
      return "polygon(20% 15%,35% 15%,40% 25%, 50% 34%,60% 25%,65% 15%,80% 15%,100% 31%,85% 44%,80% 40%,80% 100%,20% 100%,20% 40%,15% 44%,0 31%)";
    case "mangalonga":
      return "polygon(8% 35%,16% 18%,35% 15%,40% 25%,60% 25%,65% 15%,84% 18%, 92% 35%,98% 84%,85% 85%,80% 40%,80% 100%,20% 100%,20% 40%,15% 85%,2% 84%)";
    case "blusao":
      return "polygon(8% 35%,16% 18%,35% 15%,40% 25%,60% 25%,65% 15%,84% 18%, 92% 35%,98% 84%,85% 85%,80% 40%,80% 100%,20% 100%,20% 40%,15% 85%,2% 84%)";
    case "canguru":
      return "polygon(8% 35%,16% 18%,35% 15%,40% 25%,60% 25%,65% 15%,84% 18%, 92% 35%,98% 84%,85% 85%,80% 40%,80% 100%,20% 100%,20% 40%,15% 85%,2% 84%)";
    case "infantillonga":
      return "polygon(8% 35%,16% 18%,35% 15%,40% 25%,60% 25%,65% 15%,84% 18%, 92% 35%,98% 84%,85% 85%,80% 40%,80% 100%,20% 100%,20% 40%,15% 85%,2% 84%)";
    case "regata":
      return "polygon(29% 15%,35% 15%,40% 25%,60% 25%,65% 15%,71% 15%,77% 30%,80% 54%,80% 100%,20% 100%,20% 54%,23% 30%)";
    case "machao":
      return "polygon(20% 17%,35% 15%,40% 25%,60% 25%,65% 15%,80% 17%,86% 22%,83% 38%,80% 40%,80% 100%,20% 100%,20% 41%,17% 38%,14% 22%)";
    case "polo":
      return "polygon(20% 15%,35% 15%,45% 18%,55% 18%,65% 15%,80% 15%,100% 31%,85% 44%,80% 40%,80% 100%,20% 100%,20% 40%,15% 44%,0 31%)";
    case "oversized":
      return "polygon(20% 15%,35% 15%,45% 18%,55% 18%,65% 15%,80% 15%,100% 31%,85% 44%,80% 40%,80% 100%,20% 100%,20% 40%,15% 44%,0 31%)";
    case "estonada":
      return "polygon(20% 15%,35% 15%,45% 18%,55% 18%,65% 15%,80% 15%,100% 31%,85% 44%,80% 40%,80% 100%,20% 100%,20% 40%,15% 44%,0 31%)";
    case "babylook":
      return "polygon(16% 20%,35% 13%,40% 20%,60% 20%,65% 13%,86% 20%,100% 31%,85% 44%,80% 40%, 76% 67%,80% 100%,20% 100%, 24% 65% ,20% 40%,15% 44%,0 31%)";
    case "babylookv":
      return "polygon(16% 20%,35% 13%,40% 20%,50% 28%,60% 20%,65% 13%,86% 20%,100% 31%,85% 44%,80% 40%, 76% 67%,80% 100%,20% 100%, 24% 65% ,20% 40%,15% 44%,0 31%)";
    default:
      return "polygon(20% 15%, 35% 15%, 40% 25%, 60% 25%,65% 15%,80% 15%, 100% 31%, 85% 44%, 80% 40%, 80% 100%, 20% 100%, 20% 40%, 15% 44%, 0 31%)";
  }
}

const handleModelCostas = model => {

  switch (model) {
    case "mangalonga":
      return "polygon(8% 35%,16% 18%,35% 15%,40% 18%,60% 18%,65% 15%,84% 18%, 92% 35%,98% 84%,85% 85%,80% 40%,80% 100%,20% 100%,20% 40%,15% 85%,2% 84%)";
    case "canguru":
      return "polygon(8% 35%,16% 18%,35% 15%,40% 18%,60% 18%,65% 15%,84% 18%, 92% 35%,98% 84%,85% 85%,80% 40%,80% 100%,20% 100%,20% 40%,15% 85%,2% 84%)";
    case "blusao":
      return "polygon(8% 35%,16% 18%,35% 15%,40% 18%,60% 18%,65% 15%,84% 18%, 92% 35%,98% 84%,85% 85%,80% 40%,80% 100%,20% 100%,20% 40%,15% 85%,2% 84%)";
    case "infantillonga":
      return "polygon(8% 35%,16% 18%,35% 15%,40% 18%,60% 18%,65% 15%,84% 18%, 92% 35%,98% 84%,85% 85%,80% 40%,80% 100%,20% 100%,20% 40%,15% 85%,2% 84%)";
    case "regata":
      return "polygon(29% 15%,35% 15%,40% 18%,60% 18%,65% 15%,71% 15%,77% 30%,80% 54%,80% 100%,20% 100%,20% 54%,23% 30%)";
    case "machao":
      return "polygon(20% 17%,35% 15%,40% 18%,60% 18%,65% 15%,80% 17%,86% 22%,83% 38%,80% 40%,80% 100%,20% 100%,20% 41%,17% 38%,14% 22%)";
    case "babylook":
      return "polygon(16% 20%,35% 13%,40% 15%,60% 15%,65% 13%,86% 20%,100% 31%,85% 44%,80% 40%, 76% 67%,80% 100%,20% 100%, 24% 65% ,20% 40%,15% 44%,0 31%)";
    case "babylookv":
      return "polygon(16% 20%,35% 13%,40% 15%,60% 15%,65% 13%,86% 20%,100% 31%,85% 44%,80% 40%, 76% 67%,80% 100%,20% 100%, 24% 65% ,20% 40%,15% 44%,0 31%)";
    default:
      return "polygon(20% 15%, 35% 15%, 40% 18%, 60% 18%,65% 15%,80% 15%, 100% 31%, 85% 44%, 80% 40%, 80% 100%, 20% 100%, 20% 40%, 15% 44%, 0 31%)";
  }
}

const handleModelWidth = model => {
  switch (model) {
    case "babylook":
      return "230px";
    case "babylookv":
      return "230px";
    case "infantil":
      return "200px";
    case "infantillonga":
      return "200px";
    default:
      return "250px";
  }
}

const handleModelHeight = model => {
  switch (model) {
    case "babylook":
      return "250px";
    case "babylookv":
      return "250px";
    case "infantil":
      return "250px";
    case "infantillonga":
      return "250px";
    default:
      return "300px";
  }
}

const handleColor = color => {
  switch (color) {
    case "cinzamedio":
      return "#d6d6d6";
    case "cinzamescla":
      return "#dddddd";
    case "cinzapreta":
      return "#dddddd" ;
    case "cinzamarinho":
      return "#dddddd" ;
    case "cinzabordo":
      return "#dddddd" ;
    case "chumbo":
      return "#484f59" ;
    case "preto":
      return "#000" ;
    case "pretomescla":
      return "#35373b" ;
    case "canario":
      return "#e4ce19";
    case "ouro":
      return "#ddbd50";
    case "mostarda":
      return "#c49a4e";
    case "laranja":
      return "#d5732c" ;
    case "bebe":
      return "#fed6d6" ;
    case "goiaba":
      return "#e19c9f" ;
    case "pink":
      return "#bd3873" ;
    case "vermelho":
      return "#c40d29" ;
    case "bordo":
      return "#682634" ;
    case "marrom":
      return "#6e5447" ;
    case "turquesa":
      return "#91bed1" ;
    case "jade":
      return "#038086" ;
    case "royal":
      return "#034694" ;
    case "marinho":
      return "#28324d" ;
    case "lilas":
      return "#8d8ab9" ;
    case "roxo":
      return "#513977" ;
    case "fluor":
      return "#99bc3e" ;
    case "bandeira":
      return "#0d904e" ;
    case "folha":
      return "#0b4930" ;
    case "musgo":
      return "#253825" ;
    case "offwhite":
      return "#efe9d8" ;
    default:
      return "#e7e7e7";
  }
}

const handlerModelStart = model => {
  switch (model) {
    case "camisetav":
      return "100px";
    case "babylook":
      return "50px";
    case "babylookv":
      return "70px";
    case "infantil":
      return "60px";
    case "infantillonga":
      return "60px";
    case "regata":
      return "80px";
    case "polo":
      return "80px";
    case "oversized":
      return "80px";
    case "estonada":
      return "80px";
    default:
      return "75px";
  }
}

const handlerModelCostasStart = model => {
  switch (model) {
    case "babylook":
      return "38px";
    case "babylookv":
      return "38px";
    case "infantil":
      return "43px";
    case "infantillonga":
      return "43px";
    default:
      return "55px";
  }
}

const handlerModelSpace = model => {
  switch (model) {
    case "infantil":
      return "55px";
    case "infantillonga":
      return "55px";
    default:
      return "65px";
  }
}

const handleModelAlign = alinhamento => {
  switch (alinhamento) {
    case "direita":
      return "flex-start";
    case "esquerda":
      return "flex-end";
    default:
      return "center";
  }
}

const handleBorderShipmentSelection = (selected, option) => {
  if (selected===option) {
    return "3px solid #f5a01a !important";
  } else {
    return "0";
  }
}

const handlePaddingShipmentSelection = (selected, option) => {
  if (selected===option) {
    return "16px 8px 8px 8px";
  } else {
    return "16px 0 8px 8";
  }
}

const handleColorShipmentSelection = (selection) => {
  if (!selection) return "#E7EDFC !important"
}

const StyledContainer = styled(Container)`
  width: 75%;

  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
  }

  max-width: 670px;
  margin: 0px auto 48px;

  text-align: center;

  select {
    padding:2px 4px;
  }
`

const ModeloExample = styled.div`
  height: 300px;
  display: flex;
`

const ModeloEtiquetaExample = styled(ModeloExample)`
  height: 200px;
`

const Modelo = styled.div`
  margin: auto;
  width: ${props => handleModelWidth(props.model)};
  height: ${props => handleModelHeight(props.model)};
  background-color: ${props => handleColor(props.color)};
  clip-path: ${props => handleModel(props.model)};

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`

const ModeloCostas = styled(Modelo)`
  clip-path: ${props => handleModelCostas(props.model)};
`

const ModeloEtiqueta = styled(Modelo)`
  width: 200px;
  height: 100px;
  clip-path: polygon(0% 5%,25% 5%,30% 20%,70% 20%,75% 5%,100% 5%,100% 5%,101% 86%,102% 102%,83% 102%,0% 100%,0% 40%,0% 44%,0 5%);
`

const Quadro = styled.div`
  display: ${props => props.estampa==="sim" ? "block" : "none"};
  background-color: white;
  width: ${props => "calc(3.5*"+props.largura+"px)"};
  height: ${props => "calc(3.5*"+props.altura+"px)"};
  margin: ${props => "calc("+handlerModelStart(props.model)+" + 4*"+props.gola+"px)"} ${props => handlerModelSpace(props.model)} 0 ${props => handlerModelSpace(props.model)};
  align-self: ${props => handleModelAlign(props.alinhamento)};
`

const QuadroCostas = styled(Quadro)`
  margin: ${props => "calc("+handlerModelCostasStart(props.model)+" + 4*"+props.gola+"px)"} ${props => handlerModelSpace(props.model)} 0 ${props => handlerModelSpace(props.model)};
`

const QuadroEtiqueta = styled(Quadro)`
  margin: ${props => "calc(20px + 4*"+props.gola+"px)"} 65px 0 65px;
  align-self: center;
`

const Infos = styled.div`
  input {
    margin-right: 6px;
  }

`

const IsDetailed = styled.div`
  display: inline-block;

  &:first-child {
    margin-right: 24px;
  }
`

const Details = styled.div`
  width: 75%;
  margin: 12px auto 0;

  display: flex !important;
  flex-flow: column nowrap;

  ol, ul {
    pading-inline-start: 25px;
    text-align: left;
    list-style-type: inherit;
  }

  p, li {
    margin-bottom: 24px;
    ${props => props.theme.font_size.regular}
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 90%;
  }
`

const Detail = styled.div`
  display: grid !important;
  grid-template-rows: 1;
  grid-template-columns: 7fr 1fr 0fr;
  column-gap: 2px;

  border-bottom: 1px solid #142e64;
  padding: 16px 0 8px 0;

  text-align: left;

  div {
    white-space: nowrap;
  }

  input {
    text-align: center;
    width: calc(100% - 2px);
    margin: 0 0 0 2px;
    padding: 0;
  }

  input, div, label {
    align-self: end;
  }
`

const ShipmentArea = styled(Details)``

const ShipmentOption = styled(IsDetailed)`

  margin: 24px 0 0;

  @media (max-width: ${props => props.theme.screen.md}),
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;; 
    margin-right: 0; 
    text-align: left;
    padding-left: 36px;
  }
`

const ShipmentOptionArea = styled.div`
  width:75%;
  margin:auto;

  display: grid;
  grid-template-rows: 1;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media (max-width: ${props => props.theme.screen.md}),
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 24px;
  }
`

const ShipmentSelect = styled(Detail)`
  grid-template-columns: 7fr 1fr 0fr;
  column-gap: 24px;

  border-bottom: 1px solid #142e64 !important;
  border: ${props => handleBorderShipmentSelection(props.selected, props.option)};
  padding: ${props => handlePaddingShipmentSelection(props.selected, props.option)};
  color: ${props => handleColorShipmentSelection(props.selection)};

`
const ShipmentTime = styled.div``
const ShipmentPrice = styled.div`
  text-align: right;
  strong {
    color: ${props => props.theme.color.accent};
  }
`

const DetailAlinhamento = styled(Detail)`
  grid-template-columns: 6fr 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 2px;
  input {
    margin:0;
    justify-self:end;
    width:auto;
  }
  label {
    margin-right: 4px;
  }
`

const UnitPrice = styled.span`
  color: ${props => props.theme.color.accent};
  font-family: ${props => props.theme.font.bold};
`

const AreaDaLista = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
`

const LinhaDaLista = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 1;
  grid-template-columns: 1fr 2fr 2fr 2fr 1fr 2fr 2fr 1fr 2fr 1fr;
  padding: 4px 0;

  &:first-child {
    border-bottom: 1px solid #142e64;
  }

  &:last-child {
    border-bottom: 1px solid #142e64;
  }

  align-items: center;
`

const ElementoDaLista = styled.div`
  ${props => props.theme.font_size.xxxsmall};
  font-family: ${props => props.theme.font.light};

  input {
    width: 100%;
  }

`

const ExcluirButton = styled.button`
  
  color: ${props => props.theme.color.black.regular};
  display: block;

  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.white.darker};
  border-radius: 4px;
  padding: 4px 16px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.55) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }

  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Subtotal = styled.p`
  text-align: right;
  strong {
    color: ${props => props.theme.color.accent};
  }
`

const AreaDasInfos = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(16%, auto));
  grid-auto-flow: row;
`

const AreaDaInfo = styled.div`
  text-align: left;
  display: flex;
  flex-flow: column nowrap;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  label {
    ${props => props.theme.font_size.xxxsmall};
    font-family: ${props => props.theme.font.light};
  }

  input {
    ${props => props.theme.font_size.large}
    color: ${props => props.theme.color.primary};
  }

  input::placeholder {
    ${props => props.theme.font_size.xsmall};
    font-family: ${props => props.theme.font.light};
  }
`

const AreaDaInfo2 = styled(AreaDaInfo)`
  grid-column: span 2;
`
const AreaDaInfo3 = styled(AreaDaInfo)`
  grid-column: span 3;
`
const AreaDaInfo4 = styled(AreaDaInfo)`
  grid-column: span 4;
`
const AreaDaInfo5 = styled(AreaDaInfo)`
  grid-column: span 5;
`

const AreaDoCEP = styled(AreaDaInfo)`
  width: 25%;
  margin: 0 auto 24px;

  @media (max-width: ${props => props.theme.screen.md}),
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 65%;
  }
`

const OthersButtons = styled(ButtonAnchor)`
  width: 25%;
  margin: auto;

  @media (max-width: ${props => props.theme.screen.md}),
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 65%;
    margin: auto;
  }

`

const EnviarButton = styled(ButtonAnchor)`
  margin-top: 40px;
  width: 100%;
`

const DetalhesTecnicas = styled.div`
  width: 50%;
  margin: auto;
  padding: 8px;
`

const Observacoes = styled.span`
  ${props => props.theme.font_size.xxxsmall};
  font-family: ${props => props.theme.font.light};
  width: 100%;
`

const ObservacoesFrete = styled(Observacoes)`
  font-size: 22px;
`